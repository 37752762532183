<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100"
      id="kt_login_signin_form"
      @submit="onSubmitLogin"
      :validation-schema="login"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">Sign In to Control Panel</h1>
        <!--end::Title-->
      </div>
      <!--begin::Heading-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fs-6 fw-bolder text-dark">Email</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          name="email"
          autocomplete="off"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack mb-2">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6 mb-0"
            >Password</label
          >
          <!--end::Label-->

          <!--begin::Link-->
          <router-link to="/password-reset" class="link-primary fs-6 fw-bolder">
            Forgot Password ?
          </router-link>
          <!--end::Link-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          name="password"
          autocomplete="off"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->

        <button
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
          :data-kt-indicator="loading ? 'on' : null"
          :disabled="loading"
        >
          <span class="indicator-label"> Sign In </span>

          <span  v-if="loading" class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script >
import { defineComponent, ref , inject } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions , Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ElNotification } from 'element-plus'
import ApiAxiosService from "@/core/services/ApiAxiosService.ts";
import {handleError} from "@/core/helpers/response.ts";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const firebaseToken = inject('getFirebaseToken');
    const loading = ref(false);

    const submitButton = ref(null);

    if (store.getters.isUserAuthenticated) {
      router.push({ name: "dashboard" });
    }
    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });

    const showLoading = (enable) => {
      loading.value = enable;
      // if (enable) {
      //   submitButton.value?.setAttribute("data-kt-indicator", "on");
      //   submitButton.value.disabled = true;
      // } else {
      //   submitButton.value?.removeAttribute("data-kt-indicator");
      //   submitButton.value.disabled = false;
      // }

    };
    //Form submit function
    const onSubmitLogin =  (values) => {
      showLoading(true);
      ElNotification.closeAll()
      // Send login request
      ApiAxiosService.post('login' , values).then(function (res){
        Swal.fire({
          text: "You have successfully logged in!",
          icon: "success",
        });
        store.commit(Mutations.SET_AUTH , res.data.data);
        firebaseToken().then(function (token) {store.dispatch(Actions.SET_FCM_TOKEN, token);}).catch();
        setTimeout(function (){
          router.push({name : 'dashboard'});
          showLoading(false);
        },1000);


      }).catch(function (err){
        handleError(err ,'notification');
        showLoading(false);
      });


    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      loading
    };
  },
});
</script>
